/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "primary": #c3073f,
  "secondary": #4e4e50,
  "success": #0a860a,
  "danger": #6f2232,
  "warning": #b69b00,
  "info": #950740,
  "light": #999999,
  "dark": #1a1a1d,
);

@import url("https://fonts.googleapis.com/css2?family=Scada&display=swap");

$font-family-sans-serif: "Scada", sans-serif;

$enable-responsive-font-sizes: true;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
